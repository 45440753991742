import { StatusMeeting } from '@data/constants/status-meeting';
import {
  MySessionAsTriber,
  PostulationWithJobWithCompany,
  PublicationWithTriber,
  RecordedInterviewData,
  ReservationWithUser,
  ResumeWithTriber,
  SessionsPageDto,
  Triber,
  User,
} from '@tribuu-api';

import { toLocalDate } from '@shared/helpers/datetime.helper';
import { addMinutes, isFuture, isPast, subMinutes } from 'date-fns';

export class ReservationSessionCustom
  implements
    Pick<
      ReservationWithUser,
      | 'uid'
      | 'xmlUrl'
      | 'pdfUrl'
      | 'user'
      | 'recordedDataSession'
      // | 'canceledAt'
      // | 'createdAt'
      // | 'confirmedAt'
      | 'status'
    >
{
  status: ReservationWithUser.StatusEnum;
  user: User;
  uid: string;
  xmlUrl: string | null;
  pdfUrl: string | null;
  recordedDataSession: RecordedInterviewData | null;
  code: string;
  sessionId: string;
  reminderInvoice: Date | null;
  // override Dates
  canceledAt: Date | null = null;
  confirmedAt: Date | null = null;
  endAt: Date;
  startAt: Date;
  createdAt: Date;
  public is: StatusMeeting;
  isRecorded: boolean;

  constructor(
    r: ReservationWithUser,
    partialSession: { endAt: Date; startAt: Date; canceledAt: Date | null; isRecorded: boolean }
  ) {
    this.reminderInvoice = r.reminderInvoice;
    this.uid = r.uid;
    this.xmlUrl = r.xmlUrl;
    this.pdfUrl = r.pdfUrl;
    this.user = r.user;
    this.status = r.status;
    this.code = r.code;
    this.sessionId = r.sessionId;
    this.recordedDataSession = r.recordedDataSession;
    this.isRecorded = partialSession.isRecorded;
    // set custom properties
    if (r.confirmedAt) this.confirmedAt = toLocalDate(r.confirmedAt);
    if (r.canceledAt) this.canceledAt = toLocalDate(r.canceledAt) || partialSession.canceledAt;
    this.createdAt = toLocalDate(r.createdAt);
    this.endAt = toLocalDate(partialSession.endAt);
    this.startAt = toLocalDate(partialSession.startAt);

    //todo: move to helper file, duplicated code
    const { CANCELED, FINISHED, IN_PROGRESS, NON_STARTED, NOT_COMPLETED, PENDING_CONFIRMATION } =
      StatusMeeting;

    ///general status
    if (this.canceledAt) {
      this.is = CANCELED;
    } else {
      if (!this.isRecorded) {
        if (isPast(this.endAt)) {
          this.is = FINISHED;
        } else if (!this.confirmedAt) {
          this.is = PENDING_CONFIRMATION;
        } else if (isPast(subMinutes(this.startAt, 5)) && isFuture(addMinutes(this.endAt, 10))) {
          this.is = IN_PROGRESS;
        } else {
          this.is = NON_STARTED;
        }
      } else {
        //// recorded interviews case
        if (this.recordedDataSession) {
          this.is = FINISHED;
        } else if (isPast(this.endAt)) {
          this.is = NOT_COMPLETED;
        } else if (!this.confirmedAt) {
          this.is = PENDING_CONFIRMATION;
        } else {
          this.is = IN_PROGRESS;
        }
      }
    }
  }
}

export class CustomSession implements Omit<MySessionAsTriber, 'reservations'> {
  uid: string;
  roomUrl: string | null;
  public startAt: Date;
  public endAt: Date;
  public connectedAt: Date | null;
  maxParticipants: number;
  public canceledAt: Date | null;

  public canceledDetails: string | null;
  reminderSentAt: Date | null;
  // public reservations: ReservationSessionCustom[];
  publication: PublicationWithTriber | undefined;
  publicationId: string | null;
  resume: ResumeWithTriber | undefined;
  resumeId: string | null;
  createdAt: Date;
  interviewId: string | null;
  updatedAt: Date;
  public is: StatusMeeting;
  metadata: object | null;
  postulationId: string | null;
  postulation?: PostulationWithJobWithCompany | undefined;

  constructor(session: MySessionAsTriber) {
    this.uid = session.uid;
    this.roomUrl = session.roomUrl;
    this.resumeId = session.resumeId;
    this.startAt = toLocalDate(session.startAt);
    this.endAt = toLocalDate(session.endAt);
    this.connectedAt = session.connectedAt;
    this.maxParticipants = session.maxParticipants;
    this.canceledAt = session.canceledAt;
    this.canceledDetails = session.canceledDetails;
    this.reminderSentAt = session.reminderSentAt;
    // this.reservations = session.reservations.map(
    //   reservation => new ReservationSessionCustom(reservation, this.endAt)
    // );
    this.publication = session.publication;
    this.publicationId = session.publicationId;
    this.resume = session.resume;

    this.createdAt = toLocalDate(session.createdAt);
    this.updatedAt = session.updatedAt;
    this.interviewId = session.interviewId;
    this.postulationId = session.postulationId;
    this.postulation = session.postulation;
    this.metadata = null;

    const { CANCELED, FINISHED, IN_PROGRESS, NON_STARTED, PENDING_CONFIRMATION } = StatusMeeting;
    if (session.canceledAt) {
      this.is = CANCELED;
    } else if (!session.reservations[0].confirmedAt) {
      this.is = PENDING_CONFIRMATION;
    } else if (isPast(this.endAt) || session.reservations[0].recordedDataSession) {
      this.is = FINISHED;
    } else if (isPast(subMinutes(this.startAt, 5)) && isFuture(addMinutes(this.endAt, 10))) {
      this.is = IN_PROGRESS;
    } else {
      this.is = NON_STARTED;
    }
  }
  get isRecorded(): boolean {
    return Boolean(this.resumeId && this.roomUrl?.includes('entrevistas')); // is a recorded session if it has a resume and  no start Date
  }
  get inProgress(): boolean {
    return this.is === StatusMeeting.IN_PROGRESS;
  }
  get isPendingConfirmation(): boolean {
    return this.is === StatusMeeting.PENDING_CONFIRMATION;
  }
  get isFinished(): boolean {
    return this.is === StatusMeeting.FINISHED;
  }
  get isCanceled(): boolean {
    return this.is === StatusMeeting.CANCELED;
  }
  get isNonStarted(): boolean {
    return this.is === StatusMeeting.NON_STARTED;
  }

  get triber(): Triber {
    const instance = this.publication ?? this.resume;
    return instance!.triber;
  }
}

export interface SessionsPageCustom extends Pick<SessionsPageDto, 'meta'> {
  items: MySessionAsTriber[];
}
