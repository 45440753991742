import { Injectable, inject } from '@angular/core';
import { AlertService } from '@core/services/ui/alert.service';
import { StatusMeeting } from '@data/constants/status-meeting';
import {
  Action,
  Actions,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  createSelector,
  ofActionSuccessful,
} from '@ngxs/store';
import { ReservationsApi, SessionsApi } from '@tribuu-api';
import { addMinutes, isFuture, isPast, subMinutes } from 'date-fns';
import { catchError, tap } from 'rxjs';
import { AuthActions } from '../auth/auth.actions';
import { CustomSession, ReservationSessionCustom, SessionsPageCustom } from './session.model';
import { SessionActions } from './sessions.actions';

export interface SessionsStateModel {
  sessions: {
    [key: string]: CustomSession;
  };
  reservations: {
    [key: string]: ReservationSessionCustom;
  };
  meta: SessionsPageCustom['meta'];
  loading: boolean;
  loaded: boolean;
}

const DEFAULT_STATE: SessionsStateModel = {
  sessions: {},
  meta: {
    totalItems: 0,
    hasNextPage: false,
    itemsPerPage: 0,
    totalPages: 0,
    currentPage: 0,
  },
  reservations: {},
  loading: false,
  loaded: false,
};
@State<SessionsStateModel>({
  name: 'sessions',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class SessionsState implements NgxsOnInit {
  private readonly sessionsAPI = inject(SessionsApi);
  private readonly alerts = inject(AlertService);
  private readonly reservatiosnAPI = inject(ReservationsApi);
  private readonly actions = inject(Actions);

  //hooks
  ngxsOnInit(ctx: StateContext<SessionsStateModel>): void {
    const logout$ = this.actions.pipe(ofActionSuccessful(AuthActions.Logout));
    const login$ = this.actions.pipe(ofActionSuccessful(AuthActions.SignInSuccess));

    login$.subscribe(() => ctx.dispatch(new SessionActions.FetchAll()));
    logout$.subscribe(() => ctx.dispatch(new SessionActions.Reset()));
  }

  @Selector() static sessions(state: SessionsStateModel) {
    return Object.values(state.sessions);
  }
  @Selector() static isLoaded(state: SessionsStateModel) {
    return state.loaded;
  }
  @Selector() static meta(state: SessionsStateModel) {
    return state.meta;
  }
  @Selector() static isLoading(state: SessionsStateModel) {
    return state.loading;
  }

  @Selector()
  static sessionById(state: SessionsStateModel) {
    return (uid: string) => state.sessions[uid];
  }

  // @Selector() static reservationsBySessionId(state: SessionsStateModel) {
  //   return (sessionId: string) =>
  //     Object.values(state.reservations).filter(reservation => reservation.sessionId === sessionId);
  // }

  static reservationBySessionId(sessionId: string) {
    return createSelector(
      [SessionsState],
      (state: SessionsStateModel) => state.reservations[sessionId]
    );
  }

  @Action(SessionActions.FetchAll)
  retrieveAllReservations(
    ctx: StateContext<SessionsStateModel>,
    { options, query }: SessionActions.FetchAll
  ) {
    if (ctx.getState().loaded && !options.force) return;

    ctx.patchState({ loading: true });

    return this.sessionsAPI.findMySessions(query).pipe(
      tap((items) => {
        // const customSessions = data.map(item => new CustomSession(item));

        const sessions: { [key: string]: CustomSession } = items.items.reduce((acc, session) => {
          Object.assign(acc, { [session.uid]: new CustomSession(session) });
          return acc;
        }, {});

        /// map reservations

        const reservations = items.items.reduce((acc, session) => {
          Object.assign(acc, {
            [session.uid]: new ReservationSessionCustom(session.reservations[0], {
              canceledAt: session.canceledAt,
              endAt: session.endAt,
              startAt: session.startAt,
              isRecorded: Boolean(session.interviewId),
            }),
          });
          return acc;
        }, {});

        // const conversations = data
        //   .map(session => {
        //     const triber = session.publication?.triber ?? session.resume?.triber;
        //     return session.reservations.map(
        //       reservation => new ReservationConversation(reservation, triber!)
        //     );
        //   })
        //   .reduce((acc, current) => {
        //     acc.push(...current);
        //     return acc;
        //   }, []);
        // this.store.dispatch(new ConversationsActions.FetchAll(conversations));
        ctx.patchState({ sessions, reservations, loaded: true, loading: false, meta: items.meta });
      })
    );
  }

  @Action(SessionActions.Confirm)
  confirmReservation(ctx: StateContext<SessionsStateModel>, { sessionId }: SessionActions.Confirm) {
    const reservationFound = ctx.getState().reservations[sessionId];
    if (!reservationFound) return; // not found

    const session = ctx.getState().sessions[sessionId];

    ctx.patchState({ loading: true });
    return this.reservatiosnAPI.confirmReservation({ code: reservationFound.code }).pipe(
      tap(() => {
        const { startAt, endAt } = session;
        let reservationStatus = StatusMeeting.NON_STARTED;
        if (isPast(subMinutes(startAt, 5)) && isFuture(addMinutes(endAt, 10))) {
          reservationStatus = StatusMeeting.IN_PROGRESS;
        }
        const updatedReservation = {
          ...reservationFound,
          confirmedAt: new Date(),
          is: reservationStatus,
        };
        const sessionUpdated = {
          ...session,
          is: reservationStatus,
          inProgress: true,
          isFinished: false,
          isCanceled: false,
          isPendingConfirmation: false,
          isNonStarted: false,
          triber: session.triber,
          isRecorded: session.isRecorded,
        };

        ctx.patchState({
          loading: false,
          reservations: { ...ctx.getState().reservations, [sessionId]: updatedReservation },
          sessions: { ...ctx.getState().sessions, [sessionId]: sessionUpdated },
        });
      })
    );
  }

  @Action(SessionActions.Cancel)
  cancel(ctx: StateContext<SessionsStateModel>, { uid }: SessionActions.Cancel) {
    const sessionsState = ctx.getState().sessions;
    const reservationsState = ctx.getState().reservations;
    const found = sessionsState[uid];
    const reservationFound = reservationsState[uid];
    if (!found) return; // not found

    const updated = {
      ...found,
      canceledAt: new Date(),
      is: StatusMeeting.CANCELED,
      isRecorded: found.isRecorded,
      inProgress: false,
      isFinished: false,
      isCanceled: true,
      isPendingConfirmation: false,
      isNonStarted: false,
      triber: found.triber,
    };

    ctx.patchState({ sessions: { ...sessionsState, [updated.uid]: updated } });

    ctx.patchState({
      reservations: {
        ...ctx.getState().reservations,
        [updated.uid]: { ...reservationFound, is: StatusMeeting.CANCELED, canceledAt: new Date() },
      },
    });

    return this.sessionsAPI.cancelSession({ uid }).pipe(
      catchError(() => {
        this.alerts.success('Error al cancelar la sesión', 'Cancelación');
        return ctx.dispatch(new SessionActions.FetchAll({ force: true }));
      }),
      tap(() => this.alerts.success('Sesión cancelada con éxito', 'Cancelación'))
    );
  }

  @Action(SessionActions.Start)
  start(ctx: StateContext<SessionsStateModel>, { uid }: SessionActions.Start) {
    const sessionsState = ctx.getState().sessions;
    const found = sessionsState[uid];
    if (!found) return; // not found
    const updated = {
      ...found,
      startAt: new Date(),
      isRecorded: found.isRecorded,
      inProgress: true,
      isFinished: false,
      isCanceled: false,
      isNonStarted: false,
      isPendingConfirmation: false,
      triber: found.triber,
    };
    ctx.patchState({ sessions: { ...sessionsState, [updated.uid]: updated } });
  }

  @Action(SessionActions.Finish)
  finish(ctx: StateContext<SessionsStateModel>, { uid }: SessionActions.Finish) {
    const sessionsState = ctx.getState().sessions;
    const found = sessionsState[uid];
    if (!found) return; // not found

    const updated = {
      ...found,
      endAt: new Date(),
      isRecorded: found.isRecorded,
      inProgress: false,
      isFinished: true,
      isCanceled: false,
      isNonStarted: false,
      triber: found.triber,
    };
    ctx.patchState({ sessions: { ...sessionsState, [uid]: found } });
  }

  @Action(SessionActions.Reset)
  reset({ setState }: StateContext<SessionsStateModel>) {
    setState(DEFAULT_STATE);
  }

  search(items: CustomSession[], uid: string) {
    return items.find((x) => x.uid === uid) || null;
  }
}
